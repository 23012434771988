import { Button } from '@mui/material';
import { sendShopifyStorefrontRequest } from '../../utils/postToShopify';

export default function AddToCart({ variantId, buttonText, quantity}: any) {
  // const [id, setId] = useState<any>(variantId);

  // function handleChange(event: any) {
  //   setId(event.target.value);
  // }

  
  
  const addToCart = async () => {
    let localCartData = JSON.parse(
      window.localStorage.getItem('cart'),
    );

    const cartId = localCartData?.cartId
    

    if (!localCartData?.cartId) {
      console.error('There was error loading your cart');
      return;
    }

    
    let result = await sendShopifyStorefrontRequest({


      query: `
        mutation AddToCart($cartId: ID!, $variantId: ID!, $quantity: Int!) {
          cartLinesAdd(cartId: $cartId, lines: [{ quantity: $quantity, merchandiseId: $variantId}]) {
            cart {
              lines(first: 100) {
                edges {
                  node {
                    id
                    quantity
                    merchandise {
                      ... on ProductVariant {
                        product {
                          title
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: { cartId, variantId, quantity }
    });
  
    window.localStorage.setItem('status', 'dirty');
    return {
      statusCode: 200,
      body: JSON.stringify(result),
      headers: {
        "Access-Control-Allow-Origin": '*', // Allow from anywhere 
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*",
      }
    };






  



    

  }





  // TODO: check for quantity and set to sold out if 0
  // query variant ID or product available for sale
  return (
    <>


      <Button className="button" onClick={addToCart} variant="contained" sx={{width: "30vw", 
            color: "white", 
            backgroundColor: "black",
            margin: "0 auto", 
            marginLeft: "4vw",
            marginBottom: "2vh",
            marginTop: "5vh",
            ':hover': {
              bgcolor: "rgb(39, 38, 38)"
            }}}>
        {buttonText}
      </Button>
    </>
  );
}