import InformaticTexts from "./InformaticTexts";


const LearnMore = () => {
    return(
        <>
        <div style={{display:"flex", flexDirection: "row", backgroundColor:"white", marginTop: "5vh"}}>
         
         <InformaticTexts/>
        </div>
        </>
    )
}

export default LearnMore;