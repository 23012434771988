import image1 from '../../src/Components/Images/Characters/bcaashockmonkey.png'
import image2 from '../../src/Components/Images/Characters/beastGorilla.png'
import image3 from '../../src/Components/Images/Characters/gorillazBCAAwoman.png'
import image4 from '../../src/Components/Images/Characters/houndgang.png'
import image5 from '../../src/Components/Images/Characters/lizardAswhagandha.png'
import image6 from '../../src/Components/Images/Characters/pwo.png'

import GloamQR from '../../src/ImagesSRC/QRChallengeCodes/GloamsQR.png'
import GloamComicStrip from '../../src/Components/Images/Comics/GloamsGuidance6stripComic.png'

import KongComicStrip from '../../src/Components/Images/Comics/KongComic5Strip.png'

import ElaraComicStrip from '../../src/Components/Images/Comics/Elaras6ComicStripPage.png'


import comicBackground from '../../src/ImagesSRC/backgroundComic.png'



import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Card } from '@mui/material'



import * as React from 'react';

import ComicModal from './LayoutComponents/ComicModal'






 
  
export const CharacterInfo = () => {

  
    
   
   
   const images = [ 
    {
        title: "Gloam",
        img: image1,
        comic: GloamComicStrip,
        // comicBackdrop: GloamComicBackground,
        QR: GloamQR,
        text:'Deep within the enchanted Rainshadow Forest lived a unique and fascinating creature known as Gloam, the Slime-Eating Ape. Unlike ordinary apes, Gloam had an unusual diet: he thrived on a diet of magical, shimmering slime found only in the forest’s hidden groves. This slime wasn’t just any ordinary goo; it had extraordinary properties that gave Gloam unparalleled agility and glowing, bioluminescent fur. Gloam’s vibrant appearance and incredible feats quickly captured the imagination of adventurers and researchers alike. With each slime he consumed, his fur glowed brighter and his strength grew stronger, allowing him to perform feats of extraordinary acrobatics and agility. His playful antics and dazzling displays made him a favorite subject for nature documentaries and viral social media clips. His popularity skyrocketed not only because of his stunning looks and abilities but also because of his endearing personality. Gloam used his newfound energy to help lost travelers and heal the forest’s wounded creatures, earning admiration from all who witnessed his acts of kindness. Gloam became a symbol of the magical interplay between nature and wonder, inspiring millions to appreciate the hidden marvels of the natural world'
    },
    {
        title: "Kong",
        img: image2,
        comic: KongComicStrip,
        text: 'In the sprawling expanse of the ancient forest, there was a legend that roared louder than thunder: Kong, the Giant Gorilla. Towering at an incredible height and possessing unmatched strength, Kong was a colossal guardian of the wild. But it wasn’t just his size that made him famous—it was his extraordinary heart. Kong had a unique talent that set him apart from any other creature. With a gentle touch, he could communicate with the forest’s flora and fauna. His ability to nurture the jungle was legendary; flowers bloomed at his command, and animals thrived under his protection. Kong used his immense power to safeguard the forest from any threat, ensuring a harmonious balance in the ecosystem. His popularity soared as travelers and naturalists shared tales of Kong’s kindness and bravery. His story was featured in documentaries and viral videos, showcasing his majestic presence and the tranquil world he safeguarded. The world watched in awe as Kong, the giant protector, became a symbol of strength and environmental stewardship. His legacy, a beacon of hope and respect for nature, inspired millions to cherish and protect the wonders of the natural world.'
    },
    {
        title: "Elara",
        img: image3,
        comic: ElaraComicStrip,
        text: 'In the heart of the untamed Veridian Jungle lived Elara, a remarkable woman whose life intertwined seamlessly with the wilderness. Elara wasn’t just a jungle dweller; she was its guardian and whisperer. Known for her extraordinary ability to communicate with the jungle’s myriad creatures, she was revered for her unique bond with nature. Elara’s home was a masterpiece of natural architecture—vines, trees, and bioluminescent flowers formed an elegant treehouse that shimmered like a jewel among the canopy. Her daily routine involved caring for injured animals, cultivating rare medicinal plants, and harmonizing with the forest’s rhythms. Her unrivaled knowledge of jungle lore and her gentle stewardship earned her the title of “The Jungle Sage.” Her story captivated audiences worldwide through breathtaking documentaries and viral social media posts. Her mystical connection with the jungle, combined with her innovative eco-sustainable practices, showcased a harmonious way of living that resonated deeply with environmental enthusiasts and adventurers alike. Elara became a symbol of ecological balance and wisdom, inspiring millions to embrace a deeper respect for nature’s wonders and the vital role of living in harmony with the world around us.'

    },
    {
        title: "Houndgang",
        img: image4,
        comic: "houndgagncomicstrip",
        text: 'In the bustling metropolis of Hyperion City, five brothers known as the Hound Gang ruled the urban jungle with unmatched flair. Each brother brought a unique talent to the team: Rex, the leader with a tactical mind; Ace, the master mechanic with a flair for speed; Jax, the tech wizard who hacked the city’s digital grid; Finn, the skilled acrobat and parkour expert; and Max, the charismatic face who could charm anyone. The Hound Gang’s fame was fueled by their audacious and high-octane heists that merged cutting-edge technology with breathtaking physical feats. Their missions, from rescuing endangered animals from illegal trafficking to exposing corrupt officials, were executed with precision and panache. The city watched in awe as they outsmarted security systems and evaded capture with ease, all while remaining a step ahead of the law. Their adventures were widely covered on social media and news outlets, turning them into urban legends. Their blend of daring escapades and unbreakable brotherhood captured the imagination of a city yearning for heroes who defied the norm. The Hound Gang became symbols of justice and ingenuity, proving that in the heart of a city, heroes could come in the most unexpected forms'

    },
    {
        title: "Lumo",
        img: image5,
        comic: "lumocomicstrip",
        text: 'In the heart of the lush, verdant jungle, there lived a lizard named Lumo, renowned far and wide for his extraordinary talent. Unlike any ordinary lizard, Lumo possessed the remarkable ability to manipulate light. With a flick of his nimble tail, he could create mesmerizing displays of color, turning his surroundings into a canvas of radiant hues. From the deepest greens of the jungle canopy to the brilliant sunsets over the river, Lumo painted the world with his enchanting light shows. His popularity soared not just because of his breathtaking artistry, but also because he used his gift to bring joy and unity to the jungle’s diverse inhabitants. Lumo’s light shows were more than just spectacles; they were celebrations of the jungle’s vibrant ecosystem. Animals from all corners gathered to witness his magic, their differences momentarily forgotten as they reveled in the wonder of his creations. Lumo’s fame spread beyond the jungle through word-of-mouth and, soon, through social media, where his dazzling performances captivated an even larger audience. As a symbol of creativity and harmony, Lumo became a beloved figure, reminding everyone that light, in all its forms, has the power to connect and inspire.'
    },
    {
        title: "Orion",
        img: image6,
        comic: "orioncomicstrip",
        text: 'In the vibrant metropolis of Neon City, there lived an enigmatic figure named Orion, renowned for his striking purple hair that shimmered under the city lights. Orion wasn’t just known for his eye-catching appearance; he was a visionary artist whose unconventional murals transformed the urban landscape into a living gallery. By day, Orion was a mysterious figure roaming the city’s alleys and rooftops, sketching ideas for his next masterpiece. By night, he wielded a spectrum of luminous paints, turning gray walls into vibrant canvases of swirling colors and dynamic forms. His artwork didn’t just beautify the city; it told stories of its people, dreams, and untold secrets.Orion’s popularity soared as his murals became iconic landmarks and a cultural phenomenon. Social media buzzed with images of his latest creations, drawing tourists and art enthusiasts from around the globe. His work was celebrated not only for its aesthetic brilliance but also for its ability to provoke thought and inspire hope.Orion’s influence extended beyond art; he became a symbol of creativity and the power of self-expression. His unique blend of artistic vision and urban charm made him a beloved figure in Neon City, embodying the spirit of transformation and innovation'
    }
       
        
        
    ]
   




    return (
        <>
        
        <div className="characters-wrapper-div" style={{ marginTop: "10vh", 
            backgroundImage: window.innerWidth <= 481 ? `url(${comicBackground})`: `url(${comicBackground})`, 
             
            backgroundSize: window.innerWidth <= 481 ? "3700%":"280%" , 
            backgroundRepeat: "no-repeat",
            border: "10px solid black"}}
            >
            
            <div style={{
            padding: "0px", display: "flex", 
            flexDirection:"row", gap: "60px", flexWrap: "wrap", 
            justifyContent:"center", width: "100%"}}>

                {images.map((img, i) => (
                  
                    <div style={{ borderRadius: "10px", padding: "20px", 
                        
                        // backgroundColor: 
                        // img.title === "Gloam" ? img.text : "rgba(8,245,118,1)" : 
                        // img.title === "Kong" img.text ?"rgba(10,245,225,1)" : 
                        // img.title === "Elara" && character === img.text ? " rgba(63,245,3,1)": 
                        // img.title === "Houndgang" && character === img.text ? "rgba(214,32,165,1":
                        // img.title === "Lumo" && character === img.text ? "rgba(235,70,70,1)" :
                        // img.title === "Orion" && character === img.text ? "rgba(204,143,51,1": null
                    }}>
                        {/* <div className="hide-screen-comic" onClick={handleCloseDialog} style={{ display: open === true ? "block": "none"}}>
                        </div> */}
                        <Card style={{width: window.innerWidth <= 481 ? "60vw" : "30vw", 
                            padding: "70px", border: "1px solid black",  
                            borderRadius: "8px", 
                            margin: window.innerWidth <= 481 ? "0 auto" : null,
                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                            // backgroundColor:
                            // img.title === "Gloam" ? "rgba(8,245,118,1)" : 
                            // img.title === "Kong" ? "rgba(10,245,225,1)" : 
                            // img.title === "Elara" ? "rgba(63,245,3,1)": 
                            // img.title === "Houndgang" ? " rgba(214,32,165,1":
                            // img.title === "Lumo" ? "rgba(235,70,70,1)" :
                            // img.title === "Orion" ? " rgba(204,143,51,1": null
                            }}>
                            <img key={img.title} src={img.img} alt={img.title} loading="eager"
                            className="character-images" width="565vw"
                             
                            
                            style={{cursor: "pointer", border: "1px solid black", marginBottom: "1vw",
                                boxShadow: 
                                img.title === "Gloam" ? "10px 10px 5px 0px rgba(8,245,118,1)" : 
                                img.title === "Kong" ? "10px 10px 5px 0px rgba(10,245,225,1)" : 
                                img.title === "Elara" ? "10px 10px 5px 0px rgba(63,245,3,1)": 
                                img.title === "Houndgang" ? "10px 10px 5px 0px rgba(214,32,165,1":
                                img.title === "Lumo" ? "10px 10px 5px 0px rgba(235,70,70,1)" :
                                img.title === "Orion" ? " 10px 10px 5px 0px rgba(204,143,51,1": 
                                null
        
                            }}/>
                            
                                    <Accordion sx={{width:window.innerWidth <= 481 ? "60vw" : "30vw"}} >
                                        <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        >
                                        <Typography style={{fontFamily: "Laffayette Comic Pro, sans-serif"}}>{img.title}</Typography>
                                        </AccordionSummary>
                                            <AccordionDetails>
                                     
                                                    
                                                    <Typography style={{fontFamily: "Laffayette Comic Pro, sans-serif"}}>
                                                    
                                                    {/* <div>
                                                        <Button onClick={handleOpen}>Show backdrop</Button>
                                                        <Backdrop
                                                            sx={(theme) => ({ backgroundImage: `url(${GloamComicBackdrop})`, zIndex: theme.zIndex.drawer + 1 })}
                                                            open={open}
                                                            onClick={handleClose}
                                                        >
                                                        </Backdrop>
                                                    </div> */}
                                                    
                                                            <p>{img.text}</p>
                                                            {img.title === "Gloam" ?<img src={img.QR} alt="GloamQR" width="200px" style={{marginTop:"4vh"}} /> :null }
                                                            {img.title === "Gloam" ? <p>Scan the QR to Challenge Gloam in a 7-day workout challenge!</p> : null }
                                                            
                                                           <ComicModal img={img}/>

                                                          
                                                    </Typography>

                                             
                                                
                                                    
                                                  
                                                       
                                                     
                                                
                                            </AccordionDetails>
                                        </Accordion>
                                                {/* <ul style={{width: "100%", color: "black"}}>
                                                    {img.title === "Gloam" ? <li key= style={{display: character === img.text && open === true ? "block": "none", wordWrap: "break-word", width: "30vw"}}>{img.text}</li> : null}
                                                    {img.title === "Kong" ? <li key={img.title} style={{display: character === img.text && open === true ? "block": "none", wordWrap: "break-word", width: "30vw"}}>{img.text}</li> : null}
                                                    {img.title === "Elara" ? <li key={img.title} style={{display: character === img.text && open === true ? "block": "none", wordWrap: "break-word", width: "30vw"}}>{img.text}</li> : null}
                                                    {img.title === "Houndgang" ? <li key={img.title} style={{display: character === img.text && open === true ? "block": "none", wordWrap: "break-word", width: "30vw"}}>{img.text}</li> : null}
                                                    {img.title === "Lumo" ? <li key={img.title} style={{display: character === img.text && open === true ? "block": "none", wordWrap: "break-word", width: "30vw"}}>{img.text}</li> : null}
                                                    {img.title === "Orion" ? <li key={img.title} style={{display: character === img.text && open === true ? "block": "none", wordWrap: "break-word", width: "30vw"}}>{img.text}</li> : null}
                                                </ul> */}
                               


                        </Card>
                            
                        

                    </div>
                    
                        
                ))}
            </div>
        </div>
        </>
    )
}

export default CharacterInfo;