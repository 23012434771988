import  { useState } from 'react'

import { Button, Dialog } from '@mui/material'

import GloamComicBackdrop from '../Images/ComicBackdrop/GloamComicBackdrop.png'
import KongComicBackdrop from '../Images/ComicBackdrop/kongBackdrop.png'
import ElaraComicBackdrop from '../Images/ComicBackdrop/ElaraComicBackdrop.png'

import {
    TransformWrapper,
    TransformComponent,
  } from "react-zoom-pan-pinch";



const ComicModal = ({img}) => {

    const [open, setOpen] = useState(false);
    
    const handleClickOpenDialog = () => {
      setOpen(true);
    };

     
    const handleCloseDialog = () => {
        setOpen(false);
      };
    

    return (
        <>

        <Button style={{fontFamily: "Laffayette Comic Pro, sans-serif"}} variant="outlined" onClick={handleClickOpenDialog}>
            Click to read Comic!
        </Button>
                <Dialog
                    open={open}
                    onClose={handleCloseDialog}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth={"xl"}
                    sx={{border: "10px solid black"}}
                    slotProps={{

                    backdrop: {
                        sx: {
                            backgroundImage: 
                            img.title === "Gloam" ? `url(${GloamComicBackdrop})` :
                            img.title === "Kong" ? `url(${KongComicBackdrop})`: 
                            img.title === "Elara" ? `url(${ElaraComicBackdrop})`: null,
                            backgroundRepeat: "no-repeat", 
                            backgroundSize: "100vw",
                            
                            }
                        }
                    }}
                >
    

                <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                            {/* <Controls /> */}
                        <TransformComponent>
                                <img 
                                src={img.comic} 
                                alt={img.title} 
                                key={img.title} 
                                loading="lazy" 
                                style={{ maxWidth: "100%", maxHeight: "calc(100vh - 100px)"}}/>  
                            
                        
                            {/* {img.title === "Kong" ?  
                                <img 
                                src={img.comic} 
                                alt={img.title} 
                                key={img.title} 
                                loading="lazy" 
                                style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}/>  
                            
                            : null} */}
                        </TransformComponent>
                        </>
                    )}
                </TransformWrapper>
            </Dialog>
        </>
    )
}

export default ComicModal;
