
const IntegrityPolicy = () => {
    return (
        <>
        <div style={{
            marginTop: window.innerWidth <= 481 ? "30vh" : null, 
            marginLeft:  window.innerWidth <= 481 ? "7vw" : null, 
            marginBottom:  window.innerWidth <= 481 ? "5vh" : null}}>
        <h1>
        Integrity Policy
        </h1>

Last Updated: [17/12/2023]

<h2>
Introduction
</h2>

Welcome to armdactivelabs.com. This Integrity Policy outlines our commitment to transparency, security, and respect for your privacy while you visit and interact with our Website. Please take a moment to familiarize yourself with the contents of this policy.

<h2>
    Scope
</h2>

This Integrity Policy applies to all visitors, users, and customers who access or use the Website. By visiting our Website, you agree to the terms outlined in this Integrity Policy.



<h2>
Information We Collect
</h2> 

At armdactivelabs.com, we prioritize your privacy and handle personal information with the utmost care. Our Website does not require you to log in, and we do not store any personal data in any database.

However, when making a purchase, you may be required to provide personal information such as your name, address, email, and payment details. Please note that such information is directly handled by our trusted supplier and the Shopify platform, which we use for seamless and secure transactions. We do not store any payment information on our servers.

<h2>
Use of Personal Data
</h2>

The personal data collected during the checkout process is solely for the purpose of completing your transaction. We do not use this information for marketing purposes unless you explicitly opt-in to receive updates or newsletters.

<h2>
Security Measures
</h2>

We take the security of your information seriously. Our Website is hosted on secure servers, and we employ industry-standard measures to protect against unauthorized access, disclosure, alteration, or destruction of your personal data.

<h2>
Cookies and Tracking Technologies   
</h2>

Like many websites, we use cookies and similar technologies to enhance your browsing experience. Cookies are small pieces of data stored on your device that help us analyze website traffic and improve our services. You can choose to disable cookies in your browser settings, but please note that certain features of the Website may be affected.

<h2>
GDPR Compliance
</h2>

We are committed to complying with the General Data Protection Regulation (GDPR). If you are a European Union resident, you have the right to access, rectify, or erase your personal data. To exercise these rights or for any questions related to GDPR compliance, please contact us at [your email address].

<h2>
Changes to this Integrity Policy
</h2>

We reserve the right to update this Integrity Policy at any time to reflect changes in our practices or legal requirements. We will notify you of any significant changes through our Website.

<h2>
Contact Information
</h2>

If you have any questions or concerns regarding this Integrity Policy, please contact us at [your contact email].

Thank you for visiting armdactivelabs.com. We appreciate your trust in us and assure you that we are dedicated to safeguarding your privacy and providing a secure online experience.

Sincerely,

[Armin]
[armdactivelabs]
        </div>
        </>
    )
}

export default IntegrityPolicy;