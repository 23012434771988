import { sendShopifyStorefrontRequest } from '../../utils/postToShopify';
import { Button } from '@mui/material';


const RemoveFromCart = ({cartId, lineIds,item}: any) => {


    const removeSingleItemFromCart = async() => {

        const data = await sendShopifyStorefrontRequest({
            query: `
            mutation RemoveFromCart($cartId: ID!, $lineIds: [ID!]!){
              cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                cart {
                    id
                    totalQuantity
                    
                }
                errors: userErrors {
                    message
                    field
                    code
                }
              }
            }
          `,
            variables: { cartId, lineIds }
            
        });
        
        window.localStorage.setItem('status', 'dirty');
        return {
          statusCode: 200,
          body: JSON.stringify(data),
          headers: {
            "Access-Control-Allow-Origin": '*',  
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "*",
          }
        };
      }
      
      // cart.lines?.splice(lineIds, 1);
      //

    return (
        <>
            <Button  variant="outlined" sx={{height: "3vh"}} onClick={removeSingleItemFromCart}>
                X
            </Button>
        </>
    )
}

export default RemoveFromCart;