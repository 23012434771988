import { useEffect, useState } from 'react';
import * as React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { Button, Card, Divider, SwipeableDrawer } from '@mui/material';
import RemoveFromCart from './remove-from-cart';
import { sendShopifyStorefrontRequest } from '../../utils/postToShopify';
// import ProductPageContent from '../ProductPageContent';


export default function Cart({prodcutVariants}: any) {
  const [cart, setCart] = useState<any>({ id: null, lines: [] });
  // const [open, setOpen] = useState<boolean>(false);
  // eslint-disable-next-line 
  const [quant, setQuant] = useState<number>();

  


  type Anchor = 'top' | 'left' | 'bottom' | 'right';

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  

  const openCheckoutURLInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  } 

  useEffect(() => {
    const getCart = async ()  => {
      
      let localCartData = JSON.parse(
        window.localStorage.getItem('cart'));
        
        
        if (localCartData){
          const existingCart = await fetch(
            `/api/load-cart?cartId=${localCartData?.cartId}`).then(res => res.json());
            
            
        setCart({
          id: localCartData.cartId,
          checkoutUrl: localCartData.checkoutUrl,
          estimatedCost: existingCart.cart?.estimatedCost?.totalAmount?.amount,
          lines: existingCart.cart?.lines?.edges
        });
      
        return;
      }
      
      
      localCartData = await fetch('/api/create-cart').then(res => res.json());
      // .then((res) => res.json()).then((data) => console.log(data)).catch((e) => console.log(e));
    
      setCart({
        id: localCartData.cartId,
        checkoutUrl: localCartData.checkoutUrl,
        estimatedCost: null,
        lines: []
        
    });
    

    window.localStorage.setItem(
      'cart', JSON.stringify(localCartData));


    
  }
  getCart();
  



    const interval = setInterval<any>(() => {
      const state = window.localStorage.getItem('status');

      if (state && state === 'dirty') {
        getCart();
        // setOpen(true);
        window.localStorage.setItem('status', 'clean');
      }
    }, 500);

    return () => {
      clearTimeout(interval);
        
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
//   const recommendProducts = (): any[] =>   {

//     let localProductData = JSON.parse(
//       window.localStorage.getItem('data'),
//     );
//     if (localProductData.items.length  === 0 && localProductData.items !== null) {
//         console.log("No products available for recommendation.");
//         return;
//     }

//     // Filter out products that are already in the customer's cart
//     const availableProducts: any[] = [];
//     if(localProductData.items !== null){
//       for (const product of localProductData.items) {
//           if (!cart.lines.includes(product.title)) {
//               availableProducts.push(product);
//           }
//       }
//     }

//     if (availableProducts.length === 0) {
//         console.log("No products available for recommendation that are not already in the cart.");
//         return;
//     }

//     // Select at least 3 random products from the available products
//     const recommendedProducts: any[] = [];
//     const numRecommendations = Math.min(3, availableProducts.length);
//     for (let i = 0; i < numRecommendations; i++) {
//         const randomIndex = Math.floor(Math.random() * availableProducts.length);
//         recommendedProducts.push(availableProducts.splice(randomIndex, 1)[0]);
//     }

//     return recommendedProducts;
// }



const increaseQuant = async (index) => {

   
  let localCartData = JSON.parse(
    window.localStorage.getItem('cart'),
  );

  let localProductData = JSON.parse(
    window.localStorage.getItem('data'),
  );

  if(index >= 0){
    cart.lines[index].node.quantity = cart.lines[index].node.quantity + 1;
    setQuant(cart.lines[index].node.quantity);

    // const variantId = localProductData.items[index].variants[0].admin_graphql_api_id

    const arraysOfProductId = cart.lines[index].node.merchandise.product.id.split('/')[4];
    const productId = arraysOfProductId.toString();
    const parsedProductId = parseInt(productId);

    const variantId = localProductData.items.map((prod: any) => (prod.variants.filter((variant: any) => variant.product_id === parsedProductId)))
    .flat()
    .map((prodVariant: any) => prodVariant.admin_graphql_api_id).toString()
   
  
      const cartId = localCartData.cartId
      const quantity = 1;
      
  
      if (!localCartData?.cartId) {
        console.error('There was error loading your cart');
        return;
      }
  
      
      let result = await sendShopifyStorefrontRequest({
  
  
        query: `
          mutation AddToCart($cartId: ID!, $variantId: ID!, $quantity: Int!) {
            cartLinesAdd(cartId: $cartId, lines: [{ quantity: $quantity, merchandiseId: $variantId}]) {
              cart {
                lines(first: 100) {
                  edges {
                    node {
                      id
                      quantity
                      merchandise {
                        ... on ProductVariant {
                          product {
                            title
                            
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { cartId, variantId, quantity }
      });
    
      window.localStorage.setItem('status', 'dirty');
      return {
        statusCode: 200,
        body: JSON.stringify(result),
        headers: {
          "Access-Control-Allow-Origin": '*', // Allow from anywhere 
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
        }
      };
  

    
  
  }
}

const decreaseQuant = async (index) => {

  let localCartData = JSON.parse(
    window.localStorage.getItem('cart'),
  );

  // let localProductData = JSON.parse(
  //   window.localStorage.getItem('data'),
  // );

  if(index >= 0 && cart.lines[index].node.quantity > 1){
    cart.lines[index].node.quantity = cart.lines[index].node.quantity - 1;
    setQuant(cart.lines[index].node.quantity);

   
   
  
      const cartId = localCartData.cartId
      const quantity = 1;
      let lineIds = cart.lines[index].node.id;
      let data = await sendShopifyStorefrontRequest({
        query: `
        mutation RemoveFromCart($cartId: ID!, $lineIds: [ID!]!, $quantity: Int ){
          cartLinesRemove(cartId: $cartId, lineIds: $lineIds, quantity: $quantity) {
            cart {
                id
                totalQuantity
                
            }
            errors: userErrors {
                message
                field
                code
            }
          }
        }
      `,
        variables: { cartId, lineIds, quantity }
        
    });
    window.localStorage.setItem('status', 'dirty');
    return {
      
      statusCode: 200,
      body: JSON.stringify(data),
      headers: {
        "Access-Control-Allow-Origin": '*',  
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*",

      }
     
    };
   
    
}
    
}
  



// console.log(cart.lines.map(({node: item}, index: any) => (console.log(`${index}`, item))))

  return (



    <div>
    {(['right'] as const).map((anchor) => (
      <React.Fragment key={anchor}>
  
            <Badge badgeContent={cart.lines?.length} color="primary" style={{padding: "0", marginRight: "2vw", marginTop: "2vh", zIndex: "99"}}>
                <ShoppingCartIcon 
                style={{
                fontSize: "250%", 
                color: window.innerWidth <= 481 ? "white" : "black", 
                marginRight: "0vw",
                cursor: "pointer",
                pointerEvents: `${cart.lines?.length === 0 ? "none" : "all"}`
                }} 

                onClick={toggleDrawer(anchor, true)}
                />
            </Badge>
    
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
               
        >
        <div className="cart" style={{width: "80vw"}}>
     

       


        {cart.lines?.length > 0 ? (
             <>
          <div className="inner-cart" style={{marginLeft: "2vw", padding: "20px"}}>
            <div>
              DESCRIPTION
            </div>
            <div>
              QUANTITY
            </div>
            <div>
              REMOVE
            </div>
            <div>
              PRICE
            </div>
          <Divider style={{width: "75vw", marginTop: "2vh"}}/>

          </div>
       
          
          <div >
          {cart.lines.map(({node: item}, index: any) => (
            <div className="content-cart" style={{height: "10vh", padding: "20px", marginLeft: "2vw"}}>
              <div style={{display: "flex", flexDirection: "row"}}>
                    <img src={item.merchandise?.product?.images.edges[0].node.src} alt="cart" width="70vw"/>
                  <p>{item.merchandise?.product?.title} </p>   
              </div> 
              <div style={{width: "9.15vw", display: "flex", flexDirection: "row", height: "4vh"}}>
                  <Button variant="contained" sx={{backgroundColor: "darkblue", color: "white"}} onClick={() => increaseQuant(index)}>
                      +
                  </Button>
                  {/* <AddToCart 
                  variantId={item.merchandise.product.id} 
                  buttonText="+" 
                  quantity={1} 
                  options={[{title: item.merchandise.product.title, id: item.merchandise.product.id}]}
                   
                  /> */}
                  <Card sx={{width: "2vw", display: "flex", justifyContent: "center", alignItems: "center"}} >
                    {item.quantity}
                  </Card>
                  <Button variant="contained" sx={{backgroundColor: "lightgrey", color: "black"}} onClick={() => decreaseQuant(index)}>
                    -
                  </Button>
              </div>
              <div>
                <RemoveFromCart cartId={cart.id} lineIds={item.id} item={item}>
                  X
                </RemoveFromCart>
              </div>
              <div>
                {item.merchandise.priceV2.amount * item.quantity} SEK
              </div>
              <Divider style={{width: "75vw", marginTop: "2vh"}}/>
            </div>
            
              
          ))}
          </div>
          <div style={{display: "flex", flexDirection: "column", float: "right", width: "78vw"}}>
            <div style={{marginLeft: "38vw"}}>
              <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between",padding: "0px", width: "38vw" }}>
                <p>Grandtotal</p>
                <p style={{fontSize: "120%"}}>{cart.estimatedCost?.totalAmount?.amount} SEK</p>
              </div>
              <Divider sx={{width: "38vw", marginLeft: "0vw"}}/>
                <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", padding: "0px", width: "38vw" }}>
                  <p>Shipping</p>
                  <p>Arranged at Checkout</p>
                </div>
              <Divider  sx={{width: "38vw", marginLeft: "0vw"}}/>
              <div>
            </div>
            <Card style={{width: "30vw", marginLeft:"-38vw", display: "inline-block", marginTop: "-8vh"}}>
                <img src="/logo.png" alt="logo-cart" style={{width: "30vw", display: "inline-block"}}/>
            </Card>
            </div>
            <Button className="cart-checkout-button" 
            variant="contained" 
            sx={{width: "30vw", 
            color: "white", 
            backgroundColor: "black",
            marginLeft: "42vw", 
            marginTop: "-20vh",
            ':hover': {
              bgcolor: "rgb(39, 38, 38)"
            }}}
            disabled
            onClick={() => openCheckoutURLInNewTab(cart.checkoutUrl)}>
              CHECKOUT
            </Button>
            <div style={{marginTop: "20vh"}}>

            </div>
            {/* <div style={{marginTop: "20vh", marginLeft: "-1.4vw"}}>
                <h4>Products you may like</h4>
            </div> */}
            {/* <div className="recommeded-products-cart-wrapper-div">
              
              {recommendProducts().map((product: any, i: any) => (
                product.variants.map((variant: any) => (
                <div className="recommended-products-cart">
                   <ProductPageContent product={product} key={i} images={product.images} productVariants={variant}/>
                </div>
                ))
                
              ))}

            </div> */}
           
          </div>
          </>
        ) : (
          <p className="no-items">your cart is empty</p>
        )}
         
      </div>
   
        </SwipeableDrawer>
      </React.Fragment>
    ))}

   
  </div>
    
    
  );
}