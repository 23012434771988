

const ReturnPolicy = () => {

    return (
        <>
        <h3>Supplements and Nutrition</h3>
        <div>
            <h4>REFUNDS & REPLACEMENTS</h4>
                <div>
                    Any claims for misprinted/damaged/defective items must be submitted within 30 days of receiving the product:

                    For packages lost in transit, all claims must be submitted no later than 30 days after the estimated delivery date. 

                    Claims deemed an error on our part are covered at our expense and are either replaced or fully refunded.

                    If you or your customers notice an issue with the products or anything in the order that seems to be out of place, contact our customer support team and send a photograph along with a brief explanation of the problem. We will review the issue and notify you of the approval or rejection of a replacement/refund. If your claim gets approved, and you require a replacement, we will have a new order shipped to you within 3 business days. If you request a refund, a credit will be automatically applied to your credit card or original payment method within a few work days.
                </div>

                <h4>
                    REPLACEMENT/REFUND EXEMPTIONS
                </h4>

                <p>
                    Supliful will not grant a refund, credit your account, or replace a produced product in case of:
                </p>
                <ul>
                    <li>
                        
                    <p>
                    <h6> Wrong Address –</h6>
                         If you or your end customer provide an address that is considered insufficient by the courier, the shipment will be returned to our facility. You will be liable for reshipment costs once we have confirmed an updated address with you (if and as applicable).
                    </p>
                    </li>
                    <li>
                       <h6>Unclaimed – </h6> Shipments that go unclaimed are returned to our facility, and you will be liable for the cost of a reshipment to yourself or to your end customer (if and as applicable)
                    </li>
                    <li>
                        <h6>Buyer's Remorse – </h6>It is best to advise your end customers to contact you before returning any products. We do not refund orders for buyer’s remorse. Returns for products or exchange of a product are to be offered at your expense and discretion. If you choose to accept returns or offer size/product exchanges to your end customers, you would need to place a new order at your own expense
                    </li>
                    <li>
                        <h6>Rejected by Customs -</h6> If a package is returned due to customs rejection, Supliful will not issue a refund. It is the responsibility of the store owner to research import regulations for each country they are selling their products, and for the receiver to pay any necessary customs fees.
                    </li>
                    <li>
                        <h6>Product is created, but not yet shipped -</h6> In case, you wish to cancel the order while it is still in the status "Accepted", but the product has already been prepared by the fulfillment team, we will not be able to cancel and refund the order. 
                    </li>
                    <li>
                        <h6>Product Discontinuation or long Out-of-Stock – </h6>We do not provide any compensation for ad campaigns/ product creation expenses in case the product was discontinued or is out of stock for a long time.
                    </li>
                </ul>
                <h4>LATE OR MISSING REFUNDS</h4> 
                <p>If you haven’t received a refund yet, first check your Supliful profile as our system refunds with Supliful credits instead of cash.
                If you particularly requested a cash refund, then contact your credit card company; it may take some time before your refund is officially posted. Next, contact your bank - there is often some processing time before a refund is posted. If you’ve done all of this and still have not received your refund, please get in touch with our customer support team for assistance.

                <br />
                <br />

                This Policy shall be governed and interpreted in accordance with the English language, regardless of any translations made for any purpose whatsoever.</p>
        </div>
        <p>Contact: help@supliful.com</p>
        </>
    )
}

export default ReturnPolicy;