import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';

const InformaticBEE = () => {

    let BEETEXT = [{
      title: "Bee Bread Bioactivity",
      text: "Bee bread is characterized by multiple pharmacological properties, proven in vitro and Vivo. Thus, it can be stated that bee bread offers a wide field for exploiting its benefits in the food and pharmaceutical industries."
    },
    {
      title: "Bee Bread Antimicrobial Effects",
      text: " Bacteria of the genus Bacillus have been identified as an essential component of mature and stored BB and dried BP. Moreover, the study's outcomes revealed that BP and BB could be considered potential sources of bacteria-producing antimicrobial agents and/or enzymes of particular industrial importance."
    },
    {
      title: "Bee Bread Antioxidant effects",
      text: "The in vitro digestion results showed that the bioactive compounds in bee pollen and bee bread generally tended to decrease throughout digestion, with some exceptions."
    },
    {
      title: "Bee Bread for Muscles",
      text: "During research refeeding diets that contained fresh monofloral bee pollen improved muscle mass and metabolism in old, undernourished rats."
    }

  ]

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));



    
    
 return (
    <>
        
        
        <div  style={{position: "absolute",display: "flex", flexWrap:"wrap", width: "25vw", 
                        right: "50%",
                        left: "10%",
                        bottom: "-445%"}}>

            <Masonry columns={1} spacing={2} >
              {BEETEXT.map((text, index) => (
                <Item key={index} sx={{ text }} >
                  <h3>
                  {text.title}
                  </h3>
                  {text.text}
                </Item>
              ))}
            </Masonry>

        </div>
        {/* <Box sx={{ width: "36.5vw", minHeight: "39vh", backgroundColor:"#e0e1dd",padding: "20px" }}> */}
        {/* </Box> */}
        
         
    </>
 )
}

export default InformaticBEE;