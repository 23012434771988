import * as React from 'react';

import Dialog from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Button, Typography } from '@mui/material';


const answers = ['YES', 'NO'];


function SimpleDialog(props: SimpleDialogProps) {

  
 
    const { onClose, selectedValue, open } = props;

  
    const handleClose = () => {
      onClose(selectedValue);
      window.localStorage.setItem('ageAnswer', selectedValue)
    };
  
    const handleListItemClick = (value: string) => {
      onClose(value);
      
    };

 
    
  
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Are you over the age of 18?</DialogTitle>
        <List sx={{ pt: 0 }}>
          {answers.map((answer) => (
            <ListItem disableGutters key={answer}>
              <ListItemButton onClick={() => handleListItemClick(answer)}>
                <ListItemAvatar>
                  
                </ListItemAvatar>
                <ListItemText primary={answer} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
  }

const AgeRestriction = () => {

    const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = (value: any) => {
        setOpen(false);
        setSelectedValue(value);
        window.localStorage.setItem('ageAnswer', value)
        window.addEventListener("storage", console.log);
        window.dispatchEvent( new Event('storage') )
      }
  

return (
    <div>
    <Typography variant="subtitle1" component="div">
      Selected: {selectedValue}
    </Typography>
    <br />
    <Button variant="outlined" onClick={handleClickOpen}>
      Open simple dialog
    </Button>
    <SimpleDialog
      selectedValue={selectedValue}
      open={open}
      onClose={handleClose}
    />
  </div>
)
      
}

export default AgeRestriction;
