import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';



const InformaticBCAA = () => {

  let BCAATEXT = [{
    title: "BCAA for Sports", 
    text: "Scientists have proved that BCAA supplementation before and after exercise has beneficial effects for decreasing exercise-induced muscle damage and promoting muscle-protein.synthesis."
  },
  {
    title:"BCAA for Muscle Soreness",
    text: "Studies showed that BCAA supplementation reduces delayed onset muscle soreness following exercise training"
  },
  {
    title: "BCAA for Metabolism",
    text: "A study suggests BCAAs are the substrates for synthesizing nitrogenous compounds and serve as signaling molecules regulating metabolism."
  },
  {
    title: " L-Glutamine for Strength",
    text: "Research shows L-Glutamine supplementation, mainly when associated with physical exercises, improves strength and power of knee muscles."
  }]
  
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

 

  

    
 return (
    <>
        
        <div style={{display: "flex", flexWrap:"wrap", width: "25vw",
                        position: "absolute",
                        right: "50%",
                        left: "10%",
                        bottom: "-385%",
                      }}>
          
        {/* <Box sx={{ width: "38vw", minHeight: "60vh", backgroundColor:"#e0e1dd",  padding: "20px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px"}}> */}
          <Masonry columns={1} spacing={2} >
            {BCAATEXT.map((text, index) => (
              <Item key={index} sx={{ text }}>
                <h3>
                {text.title}
                </h3>
                {text.text}
              </Item>
            ))}
          </Masonry>
        {/* </Box> */}

        </div>

    </>
 )
}

export default InformaticBCAA;