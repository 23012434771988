import { useEffect, useState } from "react";
import ProductPageContent from "../ProductPageContent";
import { Divider } from "@mui/material";
// import Merch from "../Merch";


// import StyleHeader from "../LayoutComponents/StyleHeader";
// import SearchBar from "../LayoutComponents/SearchBar";
// import InformaticBCAA from "../Information/InformaticBCAA";
// import InformaticBEE from "../Information/InformaticBEE";
// import InformaticProtein from "../Information/InformaticProtein";






const MainPage = () => {
    const [data, setData] = useState<any>({});



    useEffect(() => {
      async function getData() {
  
  
        let localProductData: any = JSON.parse(
          window.localStorage.getItem('data')
        );
  
        
        
        
        if(localProductData){
          setData({
            products: localProductData,
          })
        }
        // localProductData =  await fetch('https://armdactivelabs.netlify.app/.netlify/functions/get-product-list').then(res => res.json())
        localProductData = await fetch(`https://shopifyadminapiarmdactivelabs.azurewebsites.net/api/GetAllProducts?code=${process.env.REACT_APP_AZURE_SHOPIFY_ADMIN_API_TOKEN}`, 
        {
          headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials" : "true"

          },
        }
        ).then(res => res.json());
        
        setData({
          products: localProductData,
        })
        
      
  
        window.localStorage.setItem(
          'data',
          JSON.stringify(localProductData),
        );

  
      }
      getData();
      
      
     
      
      
    }, []);

  





    // function scrollTo(ref) {
    //   if (!ref.current) return;
    //   if()
    //    myRef.current.scrollIntoView({behavior: 'smooth'}) 
    // }
  
    

    return (
        <>
        {/* <SearchBar ref={scrollTo(myRef)}/> */}
            <div className="main-page">
           
                
                <div className="main-page-product" style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "20px"}}>
                    {
                      
                      data.products?.items?.filter(product => !product.title.includes('T-shirt')).map((product:any, i: any) => (


                     
                       
                        product.variants.map((variant:any) => (
                          
                          
                          
                            <ProductPageContent product={product} key={i} images={product.images} productVariants={variant} merch={null}/>
                        
                   
                      
                        
               
                        
                  
                         
                         
                         
                          
                          ))
                        )
                      )
                       
                    
                    }  


                     
                </div>
                
                <div className="main-page-header-sale">
                <p>
                  In the fast-paced world we live in, maintaining optimal health is becoming an increasingly challenging task. Modern lifestyles often lead to nutrient gaps that can impact our overall well-being. As a result, the role of health nutrition supplements has gained prominence, offering a convenient and effective way to bridge these nutritional deficiencies. 
                  Nutritional supplements have become a cornerstone of contemporary health and wellness practices. With busy schedules, dietary restrictions, and the depletion of nutrients in soil, it's challenging for many individuals to meet their nutritional needs solely through food. Health nutrition supplements serve as a valuable complement to our diets, providing essential vitamins, minerals, and other bioactive compounds that support various bodily functions.
                  ARMD Active Labs has emerged as a trailblazer in the realm of health nutrition supplements, dedicated to enhancing people's lives through innovative products. Armd Active Labs serves as a reliable advocate for the benefits of supplementation.
                  In the ever-evolving landscape of health and wellness, ARMD Active Labs stands out as a promoter of the manifold benefits of health nutrition supplements. 
                </p>
                <img src="images/vegetarian.png" alt="" style={{width: "6%"}}/>
                <img src="images/gluten-free-2x.png" alt="" style={{width: "5%"}}/>
                <img src="images/allergen-free-2x.png" alt="" style={{width: "5%"}}/>
                <img src="images/corn-free-2x.png" alt="" style={{width: "5%"}}/>
                <img src="images/hormone-free-2x.png" alt=""style={{width: "5%"}} />
                <img src="images/lactose-free-2x.png" alt="" style={{width: "5%"}}/>
                <img src="images/no-fillers-2x.png" alt=""style={{width: "5%"}}/>
                <img src="images/non-gmo-2x.png" alt="" style={{width: "5%"}}/>
                <img src="images/sugar-free-2x.png" alt="" style={{width: "5%"}} />
                </div>
                <Divider sx={{marginTop: "30vh", width: window.innerHeight <= 481 ? "100vw" : "90vw", marginLeft: "5vw"}}/>
                
                <div className="informatic-product-component">
                     {/* {                
                    data.products?.products?.edges?.map((x: any, i: any) => (
                      <InformaticProduct product={x.node} key={i}/>
                    )) 
                    } */}
                    {/* <img src="/Images/gym.jpeg" alt="gym" width="300vw"/> */}
                    {/* <InformaticBoxes/> */}
                    {/* <div style={{}}>
                      <img src="images/stones.jpg" alt="" style={{width: "90vw", position: "relative"}} />
                      <InformaticBCAA />
                      <InformaticBEE/>
                      <InformaticProtein/>

                    </div> */}
                </div>
                
            </div>
        </>
    )
}

export default MainPage;