import './App.css';
import MainPage from './Components/Pages/MainPage';
import Header from './Components/LayoutComponents/Header';
import SmallHeader from './Components/LayoutComponents/SmallHeader';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import LearnMore from './Components/Information/LearnMore';
import Footer from './Components/LayoutComponents/Footer';
import IntegrityPolicy from './Components/Information/IntegrityPolicy';
import Merch from './Components/Merch';
import CharacterInfo from './Components/CharactersInfo';
import ReturnPolicy from './Components/Information/ReturnPolicy';



// import SpecificProduct from './Components/LayoutComponents/SpecificProduct';
// import { useEffect, useState } from 'react';
// import {motion} from 'framer-motion'



function App() {
  

  
  // const [load, setLoad] = useState<boolean>(true);

  // const handleLoading = () => {
  //   setLoad(false);
  //   }
 
  //   useEffect(()=>{
  //     window.addEventListener("load",handleLoading);
  //     return () => window.removeEventListener("load",handleLoading);
  //     },[])
  
  return (

      
  <Router>
    
  <div className='App'>

    {/* <StyleHeader/> */}
    <Header/>
    <SmallHeader/>
    <Routes>
      <Route path="/" element={<MainPage/>}/>
      <Route path="/Learn-more" element={<LearnMore/>}/>
      <Route path="/integrity-policy" element={<IntegrityPolicy/>}/>
      <Route path="/merch" element={<Merch/>}/>
      <Route path="/comic" element={<CharacterInfo/>}/>
      <Route path="/return-policy" element={<ReturnPolicy/>}/>
      {/* <Route path="/product" element={}/> */}

    </Routes>
  </div>
  <Footer/>


</Router>
  )
  
  // !load ? (
  
  // ) : <div style={{margin: "0 auto", height: "100vh", width: "100vw"}}>
  //   <motion.img
  //   src='images/weightlifter.png'
  //   animate={{
  //     scale: [1, 2, 2, 1, 1],
  //     rotate: [0, 0, 180, 180, 0],
  //     borderRadius: ["0%", "0%", "50%", "50%", "0%"]
  //   }}
  //   transition={{
  //     duration: 2,
  //     ease: "easeInOut",
  //     times: [0, 0.2, 0.5, 0.8, 1],
  //     repeat: Infinity,
  //     repeatDelay: 1
  //   }}
  //   style={{width: "30vw", height: "70vh"}}
  // />
  //   </div>
    
  // ;
}

export default App;
