
const SmallHeader = () => {


  

    return (

        <>
            <div className="App-SmallHeader">
               
            </div>
        </>
    )

}

export default SmallHeader;