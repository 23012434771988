import { Link } from "react-router-dom";


const Footer = () => {
    return (
        <>
        <div className="small-footer">
        </div>
        <div className="Footer" >
            <img src="/logo.png" alt=""  />
            <div style={{display: "flex", flexDirection: "row", justifyContent:"center"}}>
                {/* <p style={{color: "white"}}>© 2023. All rights reserved.</p> */}
                <Link style={{zIndex: 99, fontFamily: "Laffayette Comic Pro, sans-serif" ,color: "white"}} to="return-policy" content="Return policy" className="homepage-link">Return Policy</Link>
                
            </div>
        </div>

        </>
    )
}

export default Footer;