import  React, { useEffect, useRef, useState } from 'react'
import {  Box, Card, CircularProgress, Paper, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddToCart from './Cart/add-to-cart';



const Merch = ({ product, productVariants, ref} : any) => {

    let localProductData: any = JSON.parse(
        window.localStorage.getItem('data')
      );
      const [data, setData] = useState<any>([]);
      const [selectedImg, setSelectedImg] = useState<any>();

      
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setTop(0)
    setSelectedImg(images);
  };
  const handleOpen = () => {
    setOpen(true);
    setTop(200)
  };
  const [top, setTop] = useState<any>(0);

    useEffect(() => {

    if(localProductData){
        setData(
          localProductData.items?.filter(product => product.title.includes('T-shirt')),
        )
      }
      
    },[localProductData])

      let images = data?.map((product: any) => product.images.map(x => x.src));
      const counter = useRef(0);
    
      const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= images.length) {
          setLoading(false);
        }
      }


      const [loading, setLoading] = useState<boolean>(true)
      const [value, setValue] =useState('1')

      const selectImage = (img) => {
        setSelectedImg(img);
      }
      // console.log(images.map((img) => img.map((img2) => console.log(img2))))
      const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };

    return (
        <div className="main-page">
           <div className="hide-screen" onClick={handleClose} style={{display: open === true ? "block": "none"}}>
           </div>
          
           <Card className="main-page-product" style={{display: "flex", height: "", flexDirection: "column", flexWrap: "wrap", gap: "20px"}}>
                        {images.map((img) => (
                            open === true ? 
            
                           <div>
                              <div style={{display: loading ? "block" : "none"}}>
                                <CircularProgress sx={{color: "black"}}/>
                              </div>
                              
                                  <div style={{display: loading ? "none" : "block", }}>
                                    <img src={img} alt={img} width={"90vw"} 
                                    className={open === true ? 'is-active': ''} 
                                    style={{cursor: "pointer", border: "1px solid black", marginBottom: "0.935vw"}}
                                    onClick={() => selectImage(img)}
                                    onLoad={imageLoaded}/> 
                                  </div>
                             
                              
                            
                            </div>
                            
                          
                            
                              // ))
                              : null
                            
                            ))
                          
                          
                 
                          // ))
                      }
                      
                        <div>

                       
                        <img
                          src={selectedImg !== undefined && open === true  ? selectedImg : images}
                          alt={images}
                          style={{marginBottom: open === true ? top : '', width: window.innerWidth <= 481 ? "55.5vw" : "50vw" }}
                          className={open === true ? 'is-active': ''}
                          onClick={handleOpen}
                          />
                     
                     </div>
          </Card>
                     
                     {data.map((product:any, i: any) => (


                  
                          
                        product.variants.map((variant:any) => ( 
                     
                     <Paper className={open === true ? 'is-active-product-description': ''} 
            elevation={3} 
            sx={{ 
            display: open === true ? "block": "none",
            marginTop: 0}}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                    <Tab label="Description" value="1" />
                    <Tab label="Science Facts" value="2" />
                  </TabList>
                </Box>
            <TabPanel value="1">
            
            
              <div className="product-copy">
              <h1>{product.title}</h1> 
              <div dangerouslySetInnerHTML={{__html: product.body_html}}>
                
              </div>
              {/* <h2>{JSON.stringify(cost)};-  SEK </h2>  */}

              {/* <VariantForm
                vars={product}
                current={product.id}
                pick={setChosenVariant}
                setQ={setQuantity}
                /> */}
            
              </div>

              
                    <AddToCart buttonText="Add to Cart" variantId={variant.admin_graphql_api_id} options={[{title: variant.title, id: variant.admin_graphql_api_id}]} quantity={1}/>
                  </TabPanel>
            </TabContext>
        </Box>

      </Paper>
                        ))  
                      ))
              }
          
</div>      
        

            
           
      

          

    )

}

export default Merch;