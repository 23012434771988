// import fetch from 'node-fetch';



export const sendShopifyStorefrontRequest = async ({ query, variables }) => {
  const result = await fetch(
    'https://f5037f.myshopify.com/api/2024-07/graphql.json',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN,
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "*",
        // "Access-Control-Allow-Headers": "*",
        

      },
      body: JSON.stringify({ query, variables }),
    },
  );

  if (!result.ok) {
    console.error(result);
    return false;
  }

  const { data }: any = await result.json();

  return data;
}