import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';

const InformaticProtein = () => {


    let PROTEINTEXT = [{
        title: "Protein and Recovery", 
        text: " Whey protein (WP) is a widely consumed nutritional supplement that enhances strength and muscle mass during resistance training (RT) regimens. Muscle protein anabolism is acutely elevated following RT, which WP further enhances."
      },
      {
        title:"Protein and Strength",
        text: "Dietary protein supplementation significantly enhanced muscle strength and size changes during prolonged RET in healthy adults. Increasing age reduces, and training experience increases the efficacy of protein supplementation during resistance exercise training."
      },
      {
        title: "Protein and Appetite",
        text: "A meta-analysis showed that whey protein might reduce the long and short-term appetite."
      },
      {
        title: "Protein and Obesity",
        text: "A meta-analysis of a control group showed that whey protein supplementation seems to improve body weight, total fat mass, and some CVD risk factors in overweight and obese patients."
      },
      {
        title: "Protein and Body Composition",
        text: "Whey protein that contains higher amounts of essential amino acids can considerably modify muscle synthesis. After extensive studies, results showed a significant increase in lean body mass after whey protein supplementation. Researchers found no significant changes between soya protein supplementation and body composition parameters."
      },
      {
        title: "Protein Therapeutic Applications",
        text: "Whey, a protein complex derived from milk, is touted as a functional food with several health benefits. The biological components demonstrate a range of immune-enhancing properties. In addition, whey can act as an antioxidant, antihypertensive, antitumor, hypolipidemic, antiviral, antibacterial, and chelating agent. Several clinical trials have successfully concluded using whey to treat cancer, HIV, hepatitis B, cardiovascular disease, osteoporosis, and as an antimicrobial agent."
      },
      {
        title: "Protein and Fatigue",
        text: "A study conducted on basketball players during and after training showed that taking whey protein powder can cause changes in hemoglobin, red blood cells, and hematocrit in the human body. So whey protein powder can improve exercise capacity and has an anti-fatigue effect."
      }]


      
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


    
    
 return (
    <>
        
        <div style={{fontSize:"50%", display: "flex", flexDirection: "row", flexWrap:"wrap", position: "absolute", width: "25vw",
                        right: "50%",
                        left: "70%",
                        bottom: "-450%"}}>
          


        {/* <Box sx={{ width: "36.5vw", minHeight: "39vh", backgroundColor:"#e0e1dd", marginBottom: "5vh", padding: "20px",borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px"}}> */}
          <Masonry columns={1} spacing={2}>
            {PROTEINTEXT.map((text, index) => (
              <Item key={index} sx={{ text }} className="informatic-bee" >
                <h3>
                {text.title}
                </h3>
                {text.text}
              </Item>
            ))}
          </Masonry>
        {/* </Box> */}
          
        </div>

         
    </>
 )
}

export default InformaticProtein;