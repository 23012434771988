
import { Link } from 'react-router-dom';
import Cart from '../Cart/Cart';
import { Divider } from '@mui/material';
import Background from '../../ImagesSRC/backgroundCityGorillaz2.png'





const Header = () => {
  
   


    return (

        <>
        
        <div className="App-Header-Wrapper" >
        
        <div className="header-img">
        
                <div style={{zIndex: "10", marginTop: "1vh"}}>
                    <Link style={{zIndex: 99}} to="/" content='Home' className="homepage-link">Home</Link>
                    <Link style={{zIndex: 99}} to="/learn-more" content='Learn More' className="homepage-link">Learn More</Link>
                    <Link style={{zIndex: 99}} to="integrity-policy" content="Integrity Policy" className="homepage-link">Integrity Policy</Link>
                    <Link style={{zIndex: 99}} to="merch" content="Merch" className="homepage-link">Merch</Link>
                    <Link style={{zIndex: 99, fontFamily: "Laffayette Comic Pro, sans-serif"}} to="Comic" content="Comic" className="homepage-link">Comic</Link>
                </div>
                <div className="header-cart">
                    <Cart/>
                </div>
                
            </div>
            
            {/* <img src="/spikes.svg" alt="" className="spikes-img" style={{
                position: "absolute", 
                zIndex:"-1", 
                marginTop: window.innerWidth <= 481 ? "-10vh" : "-6.5vh", 
                width: window.innerWidth <= 481 ? "109vw" :" 100vw", 
                marginLeft: "0", 
                backgroundColor:"#212529"}}/> */}
            <div className="App-header" style={{position: "relative", 
                backgroundImage:  window.innerWidth <= 481 ? `url(${Background})`: `url(${Background})`
            }} 
                >
                <img src="/logo.png" alt="" style={{ marginRight: window.innerWidth <= 481 ?  "8.5vw" : null,
                    }}/>
                <div style={{
                    position: "absolute",
                    right: "50%",
                    left: "43%",
                    bottom: "47%",
                    width: "2+vw",
                    
                }}>
                    {/* <h4 style={{position: "absolute",  right: "50%", fontSize:"120%",
                        left: "3%",
                        bottom: "-420%",
                        width: "50vw"}}>Elevate Your Health: Fuel Your Potential with Premium Nutrition</h4> */}
                </div>
            </div>
            {/* <Switch
            checked={checked}
            onChange={handleChange}
            color="warning"
            inputProps={{ 'aria-label': 'controlled' }}
            /> */}

            {/* <DarkMode/> */}
            <div   id='header-stylish-dividers'
            style={{display: window.innerWidth <= 481 ? "block" : "block", 
            backgroundImage: window.innerWidth <= 481 ? `url(${Background})`: `url(${Background})`, 
            backgroundPositionY: window.innerWidth <= 481 ? "-30vh" : "-48vh" , 
            backgroundSize: window.innerWidth <= 481 ? "200vw":"100vw" , 
            backgroundRepeat: "no-repeat"}}>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
                <Divider sx={{marginTop: "1vh", width: "90vw", marginLeft: "5vw"}}/>
            </div>
        </div>
        </>
    )

}

export default Header;